<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block"/>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <team-list/>
      <locale/>
      <b-nav-item-dropdown
          class="dropdown-user"
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ auth().name }}
            </p>
            <span class="user-status">
              {{ getRoleName() }}
            </span>
          </div>
          <b-avatar
              :src="require('@/assets/images/avatars/default.png')"
              badge
              badge-variant="success"
              class="badge-minimal"
              size="40"
              variant="light-primary"
          />
        </template>
        <b-dropdown-item
            link-class="d-flex align-items-center"
            to="/profile"
        >
          <feather-icon
              class="mr-50"
              icon="UserIcon"
              size="16"
          />
          <span>{{ $i18n.t('Profile') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
        >
          <feather-icon
              class="mr-50"
              icon="LogOutIcon"
              size="16"
          />
          <span>{{ $i18n.t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {BAvatar, BDropdownItem, BLink, BNavbarNav, BNavItemDropdown,} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import initialAbility from '@appCore/libs/acl/config'
import Locale from '@appCore/layouts/components/Locale.vue'
import TeamList from './TeamList.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,

    // Navbar Components
    DarkToggler,
    Locale,
    TeamList,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  methods: {
    logout() {
      this.authRepository().logout()
          .then(response => {
            this.$store.dispatch('auth/logout')
            this.$ability.update(initialAbility)
            this.$router.push({name: 'login'})
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: this.$i18n.t('Success'),
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: this.$i18n.t('You have successfully logged out .'),
                    },
                  })
                })
          })
    }
  },
}
</script>
