<template>
  <b-nav-item-dropdown
      v-if="!isAdmin() && listTeams.length > 1"
      id="dropdown-grouped"
      class="dropdown-language team-list"
      right
      variant="link"
  >
    <template #button-content>
      <div
          class="team-name"
          style="padding: 10px 20px;"
      >
        <div
            :style="{ background: currentTeamColor }"
            class="color"
        />
        <span class="ml-50 text-body">{{ currentTeamName }}</span>
      </div>
    </template>
    <b-dropdown-item
        v-for="teamObj in listTeams"
        :key="teamObj.id"
        @click="switchTeam(teamObj)"
    >
      <div class="team-name">
        <div
            :style="{ background: teamObj.color }"
            class="color"
        />
        <span class="ml-50 text-body">{{ teamObj.name }}</span>
      </div>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {BDropdownItem, BNavItemDropdown} from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentTeam() {
      return this.$store.getters['auth/currentTeam']
    },
    currentTeamName() {
      return this.currentTeam ? this.currentTeam.name : ''
    },
    currentTeamColor() {
      return this.currentTeam ? this.currentTeam.color : ''
    },
    listTeams() {
      return this.auth().teams
    }
  },
  methods: {
    switchTeam(team) {
      if (team.id === this.currentTeam.id) return;
      this.$store.dispatch('auth/updateCurrentTeam', {currentTeam: team})
      this.$ability.update(team.ability)
      if (this.$router.currentRoute.name !== 'home') this.$router.push({name: 'home'})
    },
  },
}
</script>

<style>
.team-list .team-name {
  display: flex;
  align-items: center;
}

.team-list .team-name .color {
  width: 10px;
  height: 10px;
  border-radius: 50px;
}
</style>
